import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DocumentService } from 'src/services/document.service';
import { DocumentItem } from '../document-models/document-item';
import { UserService } from 'src/services/user.service';
import { AppConstants } from 'src/utils/app.constants';
import { PrintingService } from 'src/services/printing.service';
import { DatalayerService } from 'src/services/datalayer.service';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SearchService } from 'src/app/search/search.service';
import { DetectMobileView } from 'src/utils/detect-mobile-view';

@Component({
  selector: 'app-cps-tool-bar',
  templateUrl: './cps-tool-bar.component.html',
  styleUrls: ['./cps-tool-bar.component.scss']
})
export class CpsToolBarComponent implements OnInit, OnDestroy {
  @Input() isSearchDisabled: boolean;

  private currentDoc: DocumentItem;
  @Input() set currentDocument(doc: DocumentItem) {
    this.currentDoc = doc;
  }
  @Input() isMyOwnSearch: boolean;
  @Input('onSearch')
  public onSearch: (text: string) => number;
  @Input('onNavegate')
  public onNavegate: (next: number) => number;

  private currentDocHtml: string;
  @Input() set documentHtml(html: string) {
    // this should only be set once, since currentDocHtml is supposed to be the untampered html
    if (this.currentDocHtml === undefined
      && html !== '' && html.indexOf('skeleton skeleton-title') == -1) {
      this.currentDocHtml = html;
    }
  }
  @Input() hidePrintButton=false;

  public hasSearchBeenMade: boolean;  
  public currentFocusIndex: number;
  public totalMatchesNum: number;
  public searchText: string;
  public isSearchBoxHidden: boolean;
  public isCurrentDocBookmarked: boolean;
  public isMobile: boolean;
  public isTabletSize: boolean;
  isBucketExpandedMobile: boolean;
  private showBuckets: Subscription;

  public isNewSearchKeyword = true;

  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  private searchTimeout: any;
  private scrollToSectionTimeout: any;

  // Timeout variable to be used for delaying the search execution when user types
  private inputSearchDelay = 1000;

  constructor(public translationService: TranslateService, public documentService: DocumentService,
    private userService: UserService, private datalayerService: DatalayerService,
    public printingService: PrintingService, private searchService: SearchService) {
    this.currentFocusIndex = -1;
    this.totalMatchesNum = 0;
    this.hasSearchBeenMade = false;
    this.isSearchBoxHidden = false;
    this.isMyOwnSearch = false; 
    this.searchText = '';
    this.isMobile = DetectMobileView.detectScreenSize();
    this.isTabletSize = DetectMobileView.isScreenSizeTabletOrSmaller();

    fromEvent(window, 'resize').pipe(debounceTime(75)).subscribe(event => {
      this.isMobile = DetectMobileView.detectScreenSize();
      this.isTabletSize = DetectMobileView.isScreenSizeTabletOrSmaller();
      this.isBucketExpandedMobile = !this.isMobile;
    });
  }

  ngOnInit(): void {
    this.showBuckets = this.searchService.getIsBucketExpandedMobile()
      .pipe(takeUntil(this.destroy$)).subscribe(isBucketExpandedMobile => {
        this.isBucketExpandedMobile = isBucketExpandedMobile;
      }); 
    this.isCurrentDocBookmarked = this.userService.isCurrentDocumentBookmarked(this.currentDoc);

    if (!!this.currentDoc.searchText && this.currentDoc.searchText !== '') {
      this.searchText = this.currentDoc.searchText;
      this.hasSearchBeenMade = true;
      const thizz = this;

      if (!!this.currentDocHtml) {
        this.resetDocHtml();
        this.scrollToSectionTimeout = setTimeout(() => {
          this.currentFocusIndex = this.currentDoc.currentFocusIndex;
          this.onSearchTextChanged(this.searchText, true);
          this.scrollToHighlightedText();
        });
      } else {
        this.scrollToSectionTimeout = setTimeout(() => {
          thizz.currentFocusIndex = this.currentDoc.currentFocusIndex;
          thizz.onSearchTextChanged(this.searchText, true);
          this.scrollToHighlightedText();
        }, 500);
      }
    }
  }

  
  onSearchTextChanged(text: string, isFromOnInit: boolean=false): void {
    clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(() => {
      const thizz = this;
      if (this.isMyOwnSearch && text.length > 1) {
        //this.searchText = text;
        this.totalMatchesNum = this.onSearch(text);
        this.hasSearchBeenMade = true;
        this.searchText === '' && this.clearSearch();
        this.currentFocusIndex = -1;
        if (this.totalMatchesNum > 0) {
          this.onSearchIndexChange(true, false);
        }
        return;
      }
      //this.searchText = text;
      if (this.searchText !== null && this.searchText !== undefined && this.searchText !== '' && this.searchText.length > 1) {
        const searchRegex = new RegExp('(' + this.searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') + ')(?!([^<]+)?>)', 'gi'); // g: looks for all matches, i: case-insensitive
        const parser = new DOMParser();
        // This slice operation is to deep copy the doc HTML so it won't be modified 
        let highlightedHTML = (' ' +  this.currentDocHtml).slice(1);
        const highlightedHtml = highlightedHTML.replace(searchRegex, this.createHighlightedSpanFromMatch);
        const htmlEl = parser.parseFromString(highlightedHtml, 'text/html');
        this.documentService.setHtmlElForDocument(this.currentDoc, htmlEl.documentElement);
        this.documentService.setContentLoaded(true);
        this.isNewSearchKeyword = true;

        setTimeout(() => {
          if (!isFromOnInit) {
            this.currentFocusIndex = -1;
          }
          this.onSearchIndexChange(true, false);
        }, 300);
      } else if (this.searchText === '') {
        this.clearSearch();
      }
    }, this.inputSearchDelay);
  }

  updateIsSearchBoxHidden(): void {
    this.isSearchBoxHidden = this.currentDoc.type === AppConstants.CLININFO_TOOLS_TYPE;
  }

  toggleBookmark(): void {
    if (this.isCurrentDocBookmarked) {
      this.isCurrentDocBookmarked = false;
      this.userService.removeFavorite(this.currentDoc);
      this.datalayerService.removeBookmarkEvent();
    } else {
      this.isCurrentDocBookmarked = true;
      this.userService.addFavorite(this.currentDoc);
      this.datalayerService.addBookmarkEvent();
    }
  }

  createHighlightedSpanFromMatch(match: string, pos: any, originalText: string): string {
    return '<span class="highlighted">' + match + '</span>';
  }

  initializeSearch(): void {
    this.onSearchIndexChange(true, false);
  }

  onSearchIndexChange(isNextButtonPresed: boolean, isButtonPressed: boolean): void {
    if (this.isMyOwnSearch) {
      if(this.totalMatchesNum <= 0){
        return;
      }
      if (isNextButtonPresed) {        
        this.currentFocusIndex = this.onNavegate(this.currentFocusIndex + 1);
      } else {
        this.currentFocusIndex = this.onNavegate(this.currentFocusIndex - 1);
      }
      return;
    }
    if (this.searchText !== '') {
      this.hasSearchBeenMade = true;
      if(!isButtonPressed && this.isNewSearchKeyword){
        this.isNewSearchKeyword = false;
        this.datalayerService.onPageSearchEvent(this.searchText);
      }
      if (isNextButtonPresed) {
        this.currentFocusIndex++;
      } else {
        this.currentFocusIndex--;
      }
      if (this.currentFocusIndex > -1) {
        this.scrollToHighlightedText();
      } else {
        this.currentFocusIndex = 0;
      }

    }
  }

  scrollToHighlightedText(): void {
    const documentComponentRef = this.documentService.getComponentRef(this.currentDoc.type, this.currentDoc.id, this.currentDoc.lang);
    // TODO fix this (...what's wrong?)

    if (this.currentDoc !== null && documentComponentRef !== null && !!documentComponentRef.instance.documentWrapperElement) {
      const documentHtmlEl = documentComponentRef.instance.documentWrapperElement.nativeElement;
      const highlightedEls = documentHtmlEl.querySelectorAll('.highlighted:not(.not-visible)');
      this.totalMatchesNum = highlightedEls.length;

      if (this.currentFocusIndex < highlightedEls.length) {
        const isElementVisible = highlightedEls[this.currentFocusIndex].offsetParent !== null;

        if (isElementVisible) {
          const scrollPos = highlightedEls[this.currentFocusIndex].offsetTop - documentHtmlEl.offsetHeight / 2;
          highlightedEls[this.currentFocusIndex].scrollIntoView({block: 'center', behavior: 'auto' });
          if (this.currentFocusIndex > 0) {
            highlightedEls[this.currentFocusIndex - 1].classList.remove('marked');
          }
          if (highlightedEls[this.currentFocusIndex + 1] !== undefined) {
            highlightedEls[this.currentFocusIndex + 1].classList.remove('marked');
          }
          highlightedEls[this.currentFocusIndex].classList.add('marked');
        } else {
          highlightedEls[this.currentFocusIndex].classList.add('not-visible');
          this.scrollToHighlightedText();
        }
      } else {
        this.currentFocusIndex--;
      }
    }

  }

  clearSearch(): void {
    this.searchText = '';
    this.currentFocusIndex = !this.isMyOwnSearch ? -1 : 0;
    this.totalMatchesNum = 0;
    this.hasSearchBeenMade = false;
    this.setDisplayFullDocumentHTML(false);

    !this.isMyOwnSearch && this.resetDocHtml();
  }

  resetDocHtml(): void {
    const parser = new DOMParser();
    const htmlEl = parser.parseFromString(this.currentDocHtml, 'text/html');
    this.documentService.setHtmlElForDocument(this.currentDoc, htmlEl.documentElement);
  }

  private onResize(): void {
    this.isMobile = window.innerWidth < AppConstants.MOBILE_SIZE;
  }

  redirectToPrintPage(): void {
    let htmlEl = document.querySelector('.active-document');
    let currentDocQualifier = null;
    if (this.currentDoc.type === 'TOOL') {
      htmlEl = htmlEl.querySelector('.tool-wrapper,.doc-content-wrapper');
    }
    else if (this.currentDoc.type !== 'TOOL' && this.currentDoc.type != 'WHATSNEW' && this.currentDoc.type != 'GENERIC') {
      htmlEl = htmlEl.querySelector('.MONOGRAPH_wrapper,.patientinfo-wrapper,.condition-wrapper,.clininfo-wrapper,.resource-wrapper,.menu-document-body');
      if (this.currentDoc.type === 'MONOGRAPH') {
        htmlEl = this.documentService.getCurrentDocHtmlEl();
        const metaDataList = htmlEl.getElementsByTagName("meta");
        for (let i = 0; i < metaDataList.length; i++) {
          if (metaDataList[i].name === 'qualifier') {
            currentDocQualifier = metaDataList[i].content;
          }
        }
      }
    } else {
      htmlEl = htmlEl.querySelector('.doc-content-wrapper');
    }
    const modalData = {
      doc: this.currentDoc,
      htmlElement: htmlEl,
      docQualifier: currentDocQualifier
    };
    this.printingService.openPrintingModal(modalData, this.currentDoc.id);

    this.datalayerService.printPageEvent(this.currentDoc, htmlEl as HTMLElement);
  }

  setDisplayFullDocumentHTML(value: boolean): void {
    this.documentService.setDisplayFullHTML(value);
  }

  ngOnDestroy(): void {
    const parser = new DOMParser();
    const htmlEl = parser.parseFromString(this.currentDocHtml, 'text/html');
    if (!this.isMyOwnSearch) {
      this.documentService.setHtmlElForDocument(this.currentDoc, htmlEl.documentElement);
    } 
    this.currentDoc.searchText = this.searchText; 
    this.currentDoc.currentFocusIndex = this.currentFocusIndex;
    this.destroy$.next(true);
    this.destroy$.complete();
    clearTimeout(this.searchTimeout);
    clearTimeout(this.scrollToSectionTimeout);
  }

}
