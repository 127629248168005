<div class="modal-header">
  <h4 class="modal-title pull-left" id="manumodal-id">{{title}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true" class="manufacturer-close-x-icon">&times;</span>
  </button>
</div>
<div class="manu-modal-body">
  <div class="manuInfo" [innerHTML]="manuDetail | safe: 'html'"></div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default close-button" (click)="bsModalRef.hide()">Close</button>
</div>