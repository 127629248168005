<div id="toc-container-short" class="toc-container toc-container-ResultPage"
	[ngClass]="{ 'mobile-toc': isMobile, 'hidden zero-width': currentDocument!==null, 'mobileTocCollapsed' : (isTocCollapsed && isMobile) }"
        #tocWrapper>
    <div *ngIf="isMobile" id="mobile-toc-banner-short" class="mobile-toc-banner" (click)="toggleTocCollapsed()" #mobileTocBanner>
        <span>{{ 'toc.title' | translate }}</span>
        <span [ngClass]="{'up-chevron': isTocCollapsed, 'down-chevron': !isTocCollapsed }" class="toc-toggle-arrow"></span>
    </div>
    <div class="toc-body-wrapper" [ngClass]="{ 'hidden': isTocCollapsed }" ngxStickyfill>
        <ul class="no-padding">
            <ng-container *ngTemplateOutlet="recursiveTocTmplForShortMenu; context:{ tree: tocModel }"></ng-container>
        </ul>
    </div>
</div>

<ng-template #recursiveTocTmplForShortMenu let-tree="tree">
  <ng-container *ngIf="tree !== undefined">
    <ng-container *ngFor="let item of tree.children">
      <a *ngIf="item.tagname == 'a' || item.tagname == 'span'" [href]="item.href" [routerLink]="['./']"
         (click)="scrollToForShortMenu(item, $event.target)" [ngClass]="{'viewedSection': item.isCurrentlyViewing}" innerHTML="{{item.text}}">
      </a>
      <li *ngIf="item.tagname == 'li'" [ngClass]="{'collapsible collapsed': item.children.length > 1}"
          (click)="toggleCollapsibleSection($event)">
        <ng-container *ngTemplateOutlet="recursiveTocTmplForShortMenu; context:{ tree: item }"></ng-container>
      </li>
      <ul *ngIf="item.tagname == 'ul'">
        <ng-container *ngTemplateOutlet="recursiveTocTmplForShortMenu; context:{ tree: item }"></ng-container>
      </ul>
    </ng-container>
  </ng-container>
</ng-template>


<div id="toc-container" class="toc-container"
    [ngClass]="{ 'mobile-toc': isMobile, 'hidden zero-width': (currentDocument==null || !currentDocument.isActive), 'mobileTocCollapsed' : (isTocCollapsed && isMobile) }"
    #tocWrapper>
    <div *ngIf="currentDocument!=null && currentDocument.isActive">
        <div *ngIf="isMobile" id="mobile-toc-banner" class="mobile-toc-banner" (click)="toggleTocCollapsed()" #mobileTocBanner>
            <span>{{ 'toc.title' | translate }}</span>
            <span [ngClass]="{'up-chevron': isTocCollapsed, 'down-chevron': !isTocCollapsed }" class="toc-toggle-arrow"></span>
        </div>
        <div class="toc-body-wrapper" [ngClass]="{ 'hidden': isTocCollapsed }" ngxStickyfill>
            <ul class="no-padding">
                <ng-container *ngTemplateOutlet="recursiveTocTmpl; context:{ tree: tocModel }"></ng-container>
            </ul>
            <ul *ngIf="tocTopLinkElements.length > 0 || productImageElement !== undefined" class="top-links no-padding">
                <li *ngFor="let topLink of tocTopLinkElements" [innerHTML]="topLink.outerHTML | safe: 'html'"></li>
                <li *ngIf="productImageElement !== undefined" [innerHTML]="productImageElement.outerHTML | safe: 'html'"
                    class="document_link" (click)="openProductImageModal()"></li>
            </ul>
            <div *ngIf="tocTopLinkElements.length === 0 && !productImageElement" class="top-links"></div>
            <div *ngIf="allTablesModel !== undefined && allTablesModel !== null && allTablesModel.length > 0" class="all-tables" (click)="isAllTablesCollapsed = !isAllTablesCollapsed">
                <span [ngClass]="!isAllTablesCollapsed ? 'bold all-tables-expanded' : 'all-tables-collapsed'">
                    {{ 'toc.allTables' | translate }}
                </span>
                <ul class="condition-figure-links no-padding collapsible" [ngClass]="{'hidden': isAllTablesCollapsed}">
                    <ng-container *ngFor="let tableLink of allTablesModel">
                        <li *ngIf="tableLink !== undefined && tableLink.innerHTML !== undefined" [innerHTML]="tableLink.innerHTML  | safe: 'html'" (click)="scrollToTable(tableLink)"></li>
                    </ng-container>
                </ul>
            </div>
            <div *ngIf="allFiguresModel !== undefined && allFiguresModel !== null && allFiguresModel.length > 0" class="all-tables" (click)="isAllFiguresCollapsed = !isAllFiguresCollapsed">
                <span  [ngClass]="!isAllFiguresCollapsed ? 'bold all-figures-expanded' : 'all-figures-collapsed'">
                    {{ 'toc.allFigures' | translate }}
                </span>
                <ul class="condition-figure-links no-padding collapsible" [ngClass]="{'hidden': isAllFiguresCollapsed}">
                    <ng-container *ngFor="let figureLink of allFiguresModel">
                        <li *ngIf="figureLink !== undefined && figureLink.innerHTML !== undefined" [innerHTML]="figureLink.innerHTML  | safe: 'html'" (click)="scrollToTable(figureLink)"></li>
                    </ng-container>
                </ul>
            </div>
        </div>
    </div>
</div>

<ng-template #recursiveTocTmpl let-tree="tree">
  <ng-container *ngIf="tree !== undefined">
    <ng-container *ngFor="let item of tree.children">
      <a *ngIf="item.tagname == 'a' || item.tagname == 'span'" [href]="item.href" [routerLink]="['./']"
         (click)="scrollTo(item, $event.target)" [ngClass]="item.isCurrentlyViewing ? 'viewedSection expanded' : 'collapsed '" innerHTML="{{item.text}}">
      </a>
      <li *ngIf="item.tagname == 'li'" [ngClass]="{'collapsible': item.children.length > 1}">
        <ng-container *ngTemplateOutlet="recursiveTocTmpl; context:{ tree: item }"></ng-container>
      </li>
      <ul *ngIf="item.tagname == 'ul'">
        <ng-container *ngTemplateOutlet="recursiveTocTmpl; context:{ tree: item }"></ng-container>
      </ul>
    </ng-container>
  </ng-container>
</ng-template>

