
<div style="position: absolute" class="manufacturer-wrapper">
    <div class="manufacturer-child-position">
        <div *ngIf="contactManufacturer.statement">
            <statement class="manufacturer-font">{{ contactManufacturer.statement}}</statement>
        </div>
        <div *ngIf="!contactManufacturer.statement" class="child-div">
            <statement class="manufacturer-font">{{ 'ContactManufacturer.statement' | translate }}</statement>
        </div>
    </div>
    <div class="manufacturer-child-position">
        <button class="btn manufacturer-btn" *ngIf="contactManufacturer.buttonText">
            <a class="manufacturer-font manufacturer-font-btn" href={{contactManufacturer.url}} target="_blank">
                {{ contactManufacturer.buttonText}}
            </a>
        </button>
        <button class="btn manufacturer-btn" *ngIf="!contactManufacturer.buttonText">
            <a class="manufacturer-font manufacturer-font-btn" href={{contactManufacturer.url}} target="_blank">
                {{ 'ContactManufacturer.buttonText' | translate }}
            </a>
        </button>
    </div>
</div>
<div class="manufacturer-space-btn"></div>