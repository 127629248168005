
<ng-template #loading>
    {{ 'home-page.history.loading' | translate }}
</ng-template>

<ng-template #historyDoc let-history> 
    <p><a id="histories_{{history.id}}" class="home-history-link" (click)="navigateToDoc(history)">{{'TabBar.'+history.id | translate}}</a></p>
</ng-template>
<ng-template #historyComponent let-history>
    <p><a id="histories_{{history.id}}" class="home-history-link" (click)="navigateToDoc(history)" [innerHTML]="history.nameHTML"></a></p>
</ng-template>

<div class="home-history-container">

    <ul role="list" ngbNav #homeHistoryTab="ngbNav" [(activeId)]="active" class="nav-tabs">
        
        <li role="presentation" [ngbNavItem]="1" (click)="setActive(1)">
            <a role="listitem" ngbNavLink class="flex">
                <span class="home-history-tab-img home-bookmark-icon bookmark-unselected-icon"></span>
                <span class="home-history-tab-text">{{ 'ToolBar.bookmark' | translate}}</span>
            </a>
            <ng-template ngbNavContent class="home-history-tab-content">
                <div *ngIf="favorites$ | async as favorites; else loading">
                    <div *ngFor="let history of filter(favorites)">
                        <ng-container *ngTemplateOutlet="!ComponentType.includes(history.type)? historyComponent : historyDoc; context: { $implicit: history, list: filter(favorites) }"></ng-container>
                    </div>
                    <div *ngIf="filter(favorites).length == 0">{{ 'home-page.history.emptyFavs' | translate }}</div>
                </div>
            </ng-template>
        </li>        
        <li role="presentation" [ngbNavItem]="2" (click)="setActive(2)">
            <a role="listitem" ngbNavLink class="flex">
                <span class="home-history-tab-img recent-icon"></span>
                <span class="home-history-tab-text">{{ 'home-page.sidebar.recent' | translate }}</span>
            </a>
            <ng-template ngbNavContent class="home-history-tab-content">
                <div *ngIf="recents$ | async as histories; else loading">
                    <div *ngFor="let history of filter(histories)">
                        <ng-container *ngTemplateOutlet="!ComponentType.includes(history.type)? historyComponent : historyDoc; context: { $implicit: history, list: filter(histories) }"></ng-container>
                    </div>
                    <div *ngIf="filter(histories).length == 0">{{ 'home-page.history.emptyRecents' | translate }}</div>
                </div>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="homeHistoryTab" class="home-history-tab-content"></div>

</div>
