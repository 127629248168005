import { registerLocaleData } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'src/utils/app.constants';
import { LocalStorage } from 'src/utils/local-storage';
import { AuthService } from 'src/services/auth-services/auth.service';
import localeEn from '@angular/common/locales/en-CA';
import localeFr from '@angular/common/locales/fr-CA';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public isUserAuthenticated: boolean;
  public resetInterval: any;

  constructor(private translateService: TranslateService, private localStorage: LocalStorage, private authService: AuthService) {
    registerLocaleData(localeEn, 'en');
    registerLocaleData(localeFr, 'fr');

    // if the language param is set
    const lang = new URLSearchParams(window.location.search).get('lang');
    let preferredLang = this.localStorage.preferredLang.getValue();
    if (!!lang) {
      preferredLang = lang;
      localStorage.setPreferredLang(lang);
    }

    this.translateService.use(preferredLang);
    this.translateService.onLangChange.subscribe(
      () => {
        window.history.pushState('object', document.title, location.href.split(/[?#]/)[0]);
        // Reload the entire app after a language change, just in case for potential data issues
        // NOTE: Exception for the search results, we put them back to the homepage first
        //       see cps-utility-nav.ts for details
        if (location.href.indexOf('/search/') < 0) {
          location.reload();
        }
      }
    );

    if (!LocalStorage.getPreferredLang()) {
      this.translateService.setDefaultLang(AppConstants.ENGLISH_CODE);
      localStorage.setPreferredLang(this.translateService.getDefaultLang());
    }

    this.translateService.use(this.localStorage.preferredLang.getValue());
    this.loadJiraFeedbackScript();
  }

  ngOnInit(): void {
    this.authService.getIsUserAuthenticated().subscribe(response => {
      this.isUserAuthenticated = response;
    });


    // TEMPORARY FIX
    // for performance issues regarding memory usage, to reset the app's storage
    const resetTime = 30 * 60 * 1000;

    this.resetInterval = setInterval(() => {
      sessionStorage.setItem('scrollpos', window.scrollY.toString());
      location.reload();
    }, resetTime);

    addEventListener("DOMContentLoaded", (event) => {
      const savedDatalayer = sessionStorage.getItem('savedDatalayer');
      if (!!savedDatalayer && savedDatalayer != '') {
        const datalayer = JSON.parse(savedDatalayer);
        (<any>window).dataLayer.push(datalayer);
        // for now this var in sessionstorage is removed in document.service
      }
    });
  }


  loadJiraFeedbackScript() {
    let node = document.createElement('script');
    node.src = this.translateService.currentLang.toLowerCase() == 'en' 
    ? 'https://cpha.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/tod1zk/b/5/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-GB&collectorId=e24c1197'
    : 'https://cpha.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/tod1zk/b/6/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=fr-CA&collectorId=ca2e2f9e';
    node.type = 'text/javascript'; // set the script type
    node.async = true; // makes script run asynchronously
    node.charset = 'utf-8';
    // append to head of document
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
