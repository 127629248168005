export enum SupportedLanguages {
    ENGLISH,
    FRENCH
  }

export class AppConstants {
  static readonly ENGLISH_CODE = 'en';
  static readonly FRENCH_CODE = 'fr';
  static readonly TWITTER_URL = 'https://twitter.com/CPhAAPhC';
  static readonly FACEBOOK_URL = 'https://www.facebook.com/CPhA';
  static readonly ROUTE_DOCUMENT_TYPE = 'docType';
  static readonly COMPONENT_TYPE = 'componentType';
  static readonly MOBILE_SIZE = 768;
  static readonly PORTRAIT_MOBILE_SIZE = 480.02;
  static readonly MONOGRAPH_TYPE = 'MONOGRAPH';
  static readonly CONDITIONS_TYPE = 'CHAPTER';
  static readonly MA_CONDITIONS_TYPE = 'MA_CHAPTER';
  static readonly CLININFO_TYPE = 'CLININFO';
  static readonly CLININFO_TOOLS_TYPE = 'CLININFOTOOL';
  static readonly TOOLS_TYPE = 'TOOL';
  static readonly RESOURCE_TYPE = 'RESOURCE';
  static readonly MENU_TYPE = 'MENU';
  static readonly INDEX_TYPE = 'INDEX';
  static readonly DIRECTORY = 'DIRECTORY';
  static readonly GENERIC_TYPE = 'GENERIC';
  static readonly WHATS_NEW = 'WHATSNEW'
  static readonly NOT_FOUND_TYPE = 'NOT_FOUND';
  static readonly MEDICAL_DEVICES = 'MEDICALDEVICES';

  static readonly STATIC_COMPONENT_TYPE_LIST: string[] = [AppConstants.TOOLS_TYPE, AppConstants.INDEX_TYPE, AppConstants.GENERIC_TYPE,
    AppConstants.MEDICAL_DEVICES, AppConstants.DIRECTORY, AppConstants.MENU_TYPE, AppConstants.RESOURCE_TYPE, AppConstants.WHATS_NEW];
  static readonly STATIC_PAGE_URL_PART_LIST: string[] = ['/search', '/home'];

  static readonly IFP_TYPE = 'IFP';
  static readonly MA_PI_TYPE = 'MA_PI';
  static readonly PMA_TYPE = 'PMA';

  static readonly AUTH_SERVICE_CLIENT_ID = 'cps';
  static readonly AUTH_SERVICE_CLIENT_SECRET = 'secret';
  static readonly AUTH_SERVICE_PASSWORD_GRANT = 'password';
  static readonly AUTH_SERVICE_REFRESHTOKEN_GRANT = 'refresh_token';
  static readonly AUTH_SERVICE_AUTOLOGIN_GRANT = "autologin";

  static readonly MENU_DELAY = 300;
  static readonly USER_DDL_MENU_DELAY = 350;
}