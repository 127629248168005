import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { HomeService } from 'src/services/home.service';
import { DatalayerService } from 'src/services/datalayer.service';
import { NavigationEnd, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-cps-home-alerts',
  templateUrl: './cps-home-alerts.component.html',
  styleUrls: ['./cps-home-alerts.component.scss']
})
export class CpsHomeAlertsComponent implements OnInit, OnDestroy {

  homeAlerts$: Observable<Element[]>;
  public isClosed: boolean;
  public isHome: boolean;
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  constructor(private datalayerService: DatalayerService,
              private homeService: HomeService,
              private router: Router) {
    this.isClosed = false;
    this.isHome = false;

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (!!event.url && (event.url.startsWith('/home'))) { 
          this.isHome = true;
          this.datalayerService.bannerImpressionsEvent('homepage_messages', 'homepage_messages');
        } else if (event.url === "/") {
          this.isHome = true;        
        } else {
          this.isHome = false;
        }
      }
    }); 

  }

  ngOnInit(): void {
    this.homeAlerts$ = this.homeService.loadHomeAlerts();
  }

  closePanel(): void {
    this.isClosed = true;
    this.datalayerService.bannerCloseEvent('homepage_messages', 'homepage_messages');
  }
  
  clickEvent(linkUrl: string): void {
    let extractLinkResult = this.extractLink(linkUrl);
    this.datalayerService.bannerClickEvent('homepage_messages', 'homepage_messages', extractLinkResult);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  extractLink(innerHTML: string): string | null {
    const parser = new DOMParser();
    const doc = parser.parseFromString(innerHTML, 'text/html');
    const anchorElement = doc.querySelector('a');
    const hrefValue = anchorElement?.getAttribute('href');
    return hrefValue;
  }

}
