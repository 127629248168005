import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { DocumentItem } from 'src/app/document/document-models/document-item';
import { RouterPipe } from 'src/app/shared/router.pipe';
import { DocumentListState } from 'src/app/reducers/document-list.reducer';
import { DatalayerService } from 'src/services/datalayer.service';
import { DocumentService } from 'src/services/document.service';
import { AppConstants } from 'src/utils/app.constants';
import { debounceTime } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DetectMobileView } from 'src/utils/detect-mobile-view';
import { SearchService } from 'src/app/search/search.service';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import DocumentUtils from 'src/app/document/document-utils';
import { HeaderService } from 'src/services/header.service';

declare var tabBarComponent: any;

@Component({
  selector: 'app-cps-tab-bar',
  templateUrl: './cps-tab-bar.component.html',
  styleUrls: ['./cps-tab-bar.component.scss']
})

export class CpsTabBarComponent implements OnInit {
  @Input() context: string;

  public openDocs$: Observable<DocumentListState>;
  public innerWidth: number;
  public tabNumber: number;
  isBucketExpandedMobile: boolean;
  bucketExpandMobile: boolean;
  mobileView = false;
  private showBuckets: Subscription;
  public ComponentType: string[] = AppConstants.STATIC_COMPONENT_TYPE_LIST;

  public currDocIndex: number;
  public isActiveDocInTab: boolean;

  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  constructor(private documentService: DocumentService,
              private router: Router,
              private routerPipe: RouterPipe,
              private datalayerService: DatalayerService,
              private headerService: HeaderService,
              private searchService: SearchService) {
    this.tabNumber = 1;
    this.isActiveDocInTab = false;

    this.mobileView = DetectMobileView.detectScreenSize();

    fromEvent(window, 'resize').pipe(debounceTime(75)).subscribe(event => {
      this.onResize();
      this.mobileView = DetectMobileView.detectScreenSize();
      this.isBucketExpandedMobile = !this.mobileView;
    });
  }

  ngOnInit(): void {
    this.openDocs$ = this.documentService.getDocumentListStateObservable();
    this.currDocIndex = this.documentService.getCurrentlyActiveDocIndex();
    this.onResize();
    this.showBuckets = this.searchService.getIsBucketExpandedMobile()
      .pipe(takeUntil(this.destroy$)).subscribe(isBucketExpandedMobile => {
        this.isBucketExpandedMobile = isBucketExpandedMobile; 
      });
  }

  ngOnDestroy(): void {
    if (!!this.showBuckets) this.showBuckets.unsubscribe();
  }



  openDocument(document: DocumentItem): void {

    this.headerService.setSearchBarCondensed(false);
    const currentPath = this.router.url;
    this.datalayerService.setPageReferrer(currentPath);

    const currDoc = this.documentService.getCurrentlyViewedDocument();
    if (!!currDoc) {
      currDoc.scrollPosition = window.scrollY;
    }

    let path = '';

    if (document.type === AppConstants.TOOLS_TYPE) {
      path = this.routerPipe.transform(document.id);
    } else {
      path = this.routerPipe.transform(document.type);
    }

    // TODO: TEMPORARY FOR FIXING PERFORMANCE ISSUES
    //this.documentService.removeHtmlFromCurrentDocument();
    //const currdoc = this.documentService.getDocumentHtml(document);
    //const test = this.documentService.getCurrentDocHtmlEl();
    if (this.context === 'document') {
      // this runs on any document page
      if(document.type === AppConstants.TOOLS_TYPE) {
        this.router.navigate([path], { replaceUrl: true });

        if (document.id.indexOf('lexi-interact') < 0) {
          if (document.lang === 'en' && !document.logEn) {
            this.documentService.logToolUse(DocumentService.toolMap.get(document.id), document.lang).subscribe();
            document.logEn = true;
            document.logFr = false;
          } else if (document.lang === 'fr' && !document.logFr) {
            this.documentService.logToolUse(DocumentService.toolMap.get(document.id), document.lang).subscribe();
            document.logFr = true;
            document.logEn = false;
          }
      }

      } else if (document.type === AppConstants.MA_PI_TYPE) {
        this.router.navigate([path, document.parentDocumentID, document.id], { replaceUrl: true });
      } else {
        const requestedDocHtml = this.documentService.getDocumentHtml(document);
        // if the document component's html is missing we have to redownload it, then dispatch an action to reinitialize the TOC
        if ((DocumentUtils.isDocumentTypeRequireHtmlDownload(document.type))
          && (requestedDocHtml == null || requestedDocHtml.innerText === '' || requestedDocHtml.innerText === 'undefined')) {
          const docType = DocumentUtils.getDocumentComponentTypeFromString(document.type, document.id);
          this.documentService.redownloadDocumentHtml(document, docType);
        }

        this.router.navigate([path, document.id], { replaceUrl: true });
      }
      if (currentPath.indexOf(path) === 0) {
        // if the currentPath starts with the path, it means the 
        // router navigate will not work (only sets the URL) because the
        // same component remains loaded and the ngOnInit is never triggered
        // to load the other file, so we need to force it to set as current
        this.documentService.setDocumentAsCurrent(document.type, document.id, document.lang);
      }

    } else {
      // this runs on search result pages and document not found
      if (path.indexOf(document.id) < 0) {
        this.router.navigate([path, document.id]);
      } else {
        this.router.navigate([path]);
      }
    }
  }

  closeDocument(document: DocumentItem): void {
    
    // only need to navigate to a new tab if the closed tab was active
    if (document.isActive) {
      const referrerURL = this.datalayerService.getPageReferrer();

      console.log(window.location.href.indexOf(referrerURL));
      if (!!referrerURL && window.location.href.indexOf(referrerURL) < 0) {
        // if the referrer is a search page, or the homepage, redirect them to that url instead of doing logic
        // especially since the id of the search page could be the same as a tab
        if (referrerURL === "/" || this.isUrlContainParts(referrerURL, AppConstants.STATIC_PAGE_URL_PART_LIST)) {
          this.router.navigate([referrerURL]);
        } else {
          // when the referrer is a tab, attempt to get the tab id and navigate to that
          const referrerParts = referrerURL.split("/");
          const referrerTab = this.documentService.getDocumentForPath(referrerParts[referrerParts.length - 1],
            referrerParts[referrerParts.length - 2]);
          // if the referrer is a still opened tab, or the home/search pages we can redirect normally
          // in the case the referrer is a tab that's no longer there we need to go to the adjacent tab
          if (!!referrerTab) {
            this.openDocument(referrerTab);
          } else {
            // since tabs open on the left, the tab in index 1 is the most recently opened document
            // if the 'referrer' tab was already closed, we should just go to the most recent opened 
            // tab after that.

            this.openFirstOtherDocumentInList();
          }
        }
      } else {
        // In the case we don't have a referrer URL, we'll just switch them to the last tab
        this.openFirstOtherDocumentInList();
      }
    }

    this.documentService.closeDocument(document.type, document.id, document.lang);
    this.datalayerService.userEvent(
      { 'event': 'trackEvent-recentTabsTracking',
        'event_name': 'recent_tabs',
        'tab_removed': document.name,
        'tab_interact': this.getActive().name
      }
    );

    this.onResize();
  }

  openFirstOtherDocumentInList(): void {
    const currentDocId = this.documentService.getCurrentlyViewedDocument().id;
    const documentList = this.documentService.getDocumentList()
      .filter(d => d.id.toLowerCase() != currentDocId.toLowerCase());

    this.openDocument(documentList[0]);
  }

  getActive() : DocumentItem{
    const currDoc = this.documentService.getCurrentlyViewedDocument();
    if (currDoc !== null && currDoc !== undefined) {
      return currDoc;
    }
    return this.documentService.getDocumentList()[0]; //new DocumentItem("","","","","");
  }

  isActive(document : DocumentItem): boolean {
    return (this.documentService && document === this.documentService.getCurrentlyViewedDocument())? true:false;
  }
  isGeneric(document: DocumentItem): boolean {

    if (document.id === 'generic') {
      return true;
    }
    return (document.id.endsWith('_g')) ? true : false;
  }

  clearAllTabs() : void {
    const activedoc = this.getActive();
    this.documentService.closeAllDocuments(activedoc);
    const path = this.routerPipe.transform(activedoc.type);
    if (activedoc.type === AppConstants.MA_PI_TYPE) {
      this.router.navigate([path, activedoc.parentDocumentID, activedoc.id])
    } else {
      this.router.navigate([path, activedoc.id]);
    }
    this.datalayerService.userEvent(
      { 'event': 'trackEvent-recentTabsTracking',
        'event_name': 'recent_tabs',
        'tab_removed': 'clear_all_tabs',
        'tab_interact': this.getActive().name
      }
    );
  }

  onResize(): void {
    this.tabNumber = 1;

    this.innerWidth = window.innerWidth;

    if (this.innerWidth > 1400) {
      this.tabNumber = 5;
    } else if (this.innerWidth >= 992) {
      this.tabNumber = 4;
    } else if (this.innerWidth >= 768) {
      this.tabNumber = 3;
    }

    this.isActiveDocInTab = this.currDocIndex >= this.tabNumber;
  }

  isUrlContainParts(url: string, urlParts: string[]): boolean {
    for (const part of urlParts) {
      if (url.indexOf(part) > -1) {
        return true;
      }
    }
    return false;
  }


}
