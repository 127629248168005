<div class="whatsnew-title">
    <p class="header">
        <span class="whatsnew-icon"></span>
        {{ 'home-page.sidebar.whatsNew' | translate }}
        <span *ngIf="translationService.currentLang === 'en'">
            {{today | date:'MMM d' : '' : translationService.currentLang }}
        </span>
        <span *ngIf="translationService.currentLang === 'fr'">
            {{today | date:'d MMM' : '' : translationService.currentLang }}
        </span>
    </p>
</div>

<div class="home-sidebar-container">
    <div class="home-sidebar-panel">
        <div>
            <span class="brandName"><a [routerLink]="['monoUpdates' | routerPipe ]">{{ 'home-page.sidebar.viewMonographUpdatesLink' | translate }} </a></span>
            <span class="classification">{{ 'home-page.sidebar.viewMonographUpdatesBody' | translate }}</span>
        </div>
        <div *ngFor="let catg of categories">
            <span class="whatsnew-subHeader" [translate]="'home-page.sidebar.' + catg"></span>

            <div class="home-sidebar-item" *ngFor="let item of filterBy(catg)">
                <span *ngIf="item.filename != null" class="brandName"><a id="whatsnew_{{item.id}}" [routerLink]="['MONOGRAPH' | routerPipe,item.id]">{{item.brandName}} </a> <span class="generic">({{item.genericName}})</span></span>
                <span *ngIf="item.filename == null" class="classification"><a>{{item.brandName}} </a> <span class="generic">({{item.genericName}})</span> </span> :
                <span class="classification">{{item.classification}}</span>
            </div>
        </div>

        <div>
            <span class="whatsnew-subHeader" *ngIf="whatsNewTC.length > 0">{{ 'home-page.sidebar.newTheraputicChoices' | translate }}</span>

            <div class="home-sidebar-item" *ngFor="let item of whatsNewTC">
            <span class="brandName"><a id="whatsnew_{{item.filename.split('.')[0]}}" [routerLink]="['CHAPTER' | routerPipe,item.filename.split('.')[0]]">{{item.title}} </a></span><span class="generic">({{item.date}})</span> :
                <span class="classification">{{item.description}}</span>
            </div>
        </div>
    </div>
</div>
