import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatalayerService } from 'src/services/datalayer.service';
import { HeaderService } from 'src/services/header.service';
import { AppConstants } from 'src/utils/app.constants';
import { LocalStorage } from 'src/utils/local-storage';
import { Store } from '@ngrx/store';
import { State } from '../../reducers';
import { userProfile } from 'src/app/utility/Objects/userProfile';
import { RemoveUserProfile } from 'src/app/actions/user-profile.actions';
import { AuthService } from 'src/services/auth-services/auth.service';
import { ClearAllDocuments } from 'src/app/actions/document-list.actions';
import { ClearMenuDocument } from 'src/app/actions/document-data.actions';
import { RemoveSearchResults } from 'src/app/actions/search-result.actions';
import { RemoveSearchTerm } from 'src/app/actions/search-term.actions';
import { ClearWhatsNew } from 'src/app/actions/whatsnew.actions';
import { ClearWhatsNewTC } from 'src/app/actions/whatsnew-tc.actions';
import { ClearMessages } from 'src/app/monograph/messages.actions';
import { SessionService } from 'src/services/session.service';
import { ClearGenericList } from 'src/app/actions/generic-list.actions';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { ClearCustomizer } from 'src/app/actions/customizer.actions';
import { SearchService } from 'src/app/search/search.service';
import { DocumentService } from 'src/services/document.service';

@Component({
  selector: 'app-cps-utility-nav',
  templateUrl: './cps-utility-nav.component.html',
  styleUrls: ['./cps-utility-nav.component.scss']
})
export class CpsUtilityNavComponent implements OnInit {

  public loggedInObservable$: Observable<boolean>;
  public userProfileDetailsObservable$: Observable<any>;
  public sessionActive: boolean;
  private currentLanguage: string;
  public languageChangeText: string;
  public username: string;
  public userProfile: userProfile;

  public menu = [
    { "label" : "util-Nav.subInfo", "link" : "pkgswitch"},
    { "label" : "util-Nav.logout", "link" : "logout"}
  ]
  constructor(public translationService: TranslateService, public localStorage: LocalStorage,
              public headerService: HeaderService, public router: Router, private datalayerService: DatalayerService,
              public authService: AuthService,
              private sessionService: SessionService,
              private store: Store<State>,
              private search: SearchService,
              private documentService: DocumentService) {

    this.currentLanguage = this.translationService.currentLang;
    this.languageChangeText = this.getLanguageText();
    this.username = '';
    this.loggedInObservable$ = this.headerService.getLogin();
    this.userProfileDetailsObservable$ = AuthService.userProfileDetailsObservable$;
  }

  ngOnInit(): void {
    // Empty
  }

  changeLang(): void {
    
    const currDoc = this.documentService.getCurrentlyViewedDocument();
    if (!!currDoc) {
      currDoc.scrollPosition = 1;
    }
    this.datalayerService.languageChangedEvent((this.currentLanguage === AppConstants.ENGLISH_CODE
      ? AppConstants.FRENCH_CODE : AppConstants.ENGLISH_CODE));
    this.languageChangeText = this.headerService.changeLang(this.translationService, this.currentLanguage, this.localStorage);
    // When we have search results, since french/english terms are quite different
    // reload the whole page instead since their results are probably not what the user wants
    this.headerService.navigateToHomeIfUserInSearchResult();
  }

  getLanguageText(): string {
      return this.headerService.getLanguageText(this.currentLanguage);
  }

  logout(): void {
    const token = this.sessionService.userProfile.refresh_token;
    this.authService.removeAccessTokenData(token).subscribe();
    this.store.dispatch(new ClearAllDocuments());
    this.search.textToUpdateSearchBox.next('');
    this.clearStore();
    this.username = '';
    this.datalayerService.logoutEvent();
    this.router.navigate(["login"]);
    this.authService.setIsUserAuthenticated(false);

  }

  clearStore(): void {
    this.store.dispatch(new RemoveUserProfile());
    this.store.dispatch(new ClearAllDocuments());
    this.store.dispatch(new ClearMenuDocument());
    this.store.dispatch(new ClearGenericList());
    this.store.dispatch(new RemoveSearchResults());
    this.store.dispatch(new RemoveSearchTerm());
    this.store.dispatch(new ClearWhatsNew());
    this.store.dispatch(new ClearWhatsNewTC());
    this.store.dispatch(new ClearMessages());
    this.store.dispatch(new ClearCustomizer());
  }

  @ViewChild('userDropdown') userDropdown: NgbDropdown;
  declare userTimeout: ReturnType<typeof setTimeout>;

  public hoverOnUser(): void {
    this.userTimeout = setTimeout(() => 
      { this.userDropdown.open() }
    , AppConstants.MENU_DELAY);
  }
  public leaveUserDropdown(): void {
    if (!!this.userDropdown){
      this.userDropdown.close();
      clearTimeout(this.userTimeout);
    }
  }

  public onUtilNavMouseLeave(): void {
    if (!!this.userDropdown) {
      this.userDropdown.close();
    }
  }
  @HostListener('window:focus', ['$event'])
  onFocus(): void {
    // This is to close all opened dropdowns when user return from switching tabs 
    // otherwise, the dropdowns will sometimes stay open and it's weird
    this.leaveUserDropdown();
  }
  
  goToHomePage(): void {
    this.router.navigate(['/home']);
  }
}
