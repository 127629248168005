import { Component, OnInit, Input } from '@angular/core';
import { ContactManufacturer } from '../CPSMessages.interface';

@Component({
  selector: 'app-cps-contact-manufacturer',
  templateUrl: './cps-contact-manufacturer.component.html',
  styleUrls: ['./cps-contact-manufacturer.component.scss']
})
export class CpsContactManufacturerComponent implements OnInit {
  
  @Input() contactManufacturer: ContactManufacturer;

  ngOnInit(): void {
    // EMPTY
  }
}
