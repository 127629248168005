import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ComponentFactoryResolver, ComponentRef, Injectable, NgZone, Type, ViewContainerRef} from '@angular/core';
import {DocumentItem} from 'src/app/document/document-models/document-item';
import {Action, ActionsSubject, Store} from '@ngrx/store';
import {State} from 'src/app/reducers';
import {
  AddDocument,
  ChangeDocumentListLanguage,
  ClearTabs,
  DocumentListActionTypes,
  LoadDocumentFailed,
  ReloadTableOfContents,
  RemoveDocument,
  ScrollToSection,
  ShowDocument,
  ShowDocumentPreload,
  ShowDocumentSuccess
} from 'src/app/actions/document-list.actions';
import {DocumentComponent} from 'src/app/document/document-models/documentComponent';
import {DocumentListState} from 'src/app/reducers/document-list.reducer';
import {BehaviorSubject, Observable} from 'rxjs';
import {DatalayerService} from './datalayer.service';
import {ofType} from '@ngrx/effects';
import {ActivatedRoute, ExtraOptions, Router} from '@angular/router';
import {LoadMenuDocument} from 'src/app/actions/document-data.actions';
import {selectDocumentData, selectSpecificDocumentData} from 'src/app/reducers/document-data.selectors';
import {MenuDocumentData} from 'src/app/reducers/document-data.reducer';
import {AppConstants} from 'src/utils/app.constants';
import {HomeService} from './home.service';
import {TranslateService} from '@ngx-translate/core';
import {LoadMessageForChangedLanguage} from 'src/app/monograph/messages.actions';
import {environment} from 'src/environments/environment';
import {GenericList} from 'src/app/document/cps-generic-index/genericList.interface';
import {GenericItems} from 'src/app/document/cps-generic-index/genericItems.interface';
import {GenericListState} from 'src/app/reducers/Generic-list.reducer';
import {LoadGenericList} from 'src/app/actions/generic-list.actions';
import {SessionService} from './session.service';
import {take} from 'rxjs/operators';
import {AuthService} from './auth-services/auth.service';
import { RouterPipe } from 'src/app/shared/router.pipe';
import { DocumentHostDirective } from 'src/app/document/document-host.directive';

declare var documentService: any;

// tslint:disable: max-line-length
@Injectable()
export class DocumentService {

    public static DOC_WITH_KEYWORD_JUMP_ENABLED = ['dosage_adjustment_in_renal_impairment'];

    public static toolMap: Map<string, string> = new Map([['renalfunction', 'RENAL'], ['pillid', 'PID'], ['bodysurface', 'BODYSURFACE']]);

    private documentList: Array<DocumentItem> = new Array<DocumentItem>();
    public componentRefList: Array<ComponentRef<DocumentComponent>> = new Array<ComponentRef<DocumentComponent>>();
    public docWrapperViewContainerRef: ViewContainerRef;

    public currentUnloadedDocumentItem: DocumentItem;
    public isUserInIe: boolean;

    private brokenImagePath = 'assets/images/imageError.png';
    public skeleton: HTMLElement;

    public selectedAlpha: Map<string, string>;
    private contentLoaded$: BehaviorSubject<boolean>;
    private displayFullHTML$: BehaviorSubject<boolean>;

    public documentLock = false;
    private currentDoc: DocumentItem;

    constructor(public httpClient: HttpClient, private componentFactoryResolver: ComponentFactoryResolver,
                private store: Store<State>, private actionListener$: ActionsSubject,
                private dataLayerService: DatalayerService,
                private homeService: HomeService,
                private sessionService: SessionService,
                private translationService: TranslateService,
        private router: Router, private ngZone: NgZone, private activatedRoute: ActivatedRoute,
        private routerPipe: RouterPipe) {
        documentService = this;
        this.contentLoaded$ = new BehaviorSubject<boolean>(false);
        this.displayFullHTML$ = new BehaviorSubject<boolean>(false);

        this.isUserInIe = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
        this.store.select(state => state.documentList)
            .subscribe(action => {
                this.documentList = action.documents;
            });

        this.actionListener$.pipe(ofType(DocumentListActionTypes.RemoveDocument))
            .subscribe((action) => {
                this.showCurrentDocument();
            });
        this.actionListener$.pipe(ofType(DocumentListActionTypes.AddDocument))
            .subscribe((action) => {
                const doc = (action as ShowDocumentSuccess).document;
                if (doc.type === AppConstants.MENU_TYPE) {
                    doc.name = '';
                    this.store.dispatch(new LoadMenuDocument(doc.id, doc.lang));
                }
                if (doc.type === AppConstants.GENERIC_TYPE || doc.type === AppConstants.MEDICAL_DEVICES) {
                    store.dispatch(new LoadGenericList());
                }
                this.showDocument(doc);
            });
        this.actionListener$.pipe(ofType(DocumentListActionTypes.ShowDocument))
            .subscribe((action) => {
                this.setContentLoaded(false);
                this.sessionService.isScrollLocked = true;
                this.showDocumentPreload((action as ShowDocument).document);
            });
        this.actionListener$.pipe(ofType(DocumentListActionTypes.ShowDocumentSuccess))
            .subscribe((action) => {
                // TODO: This is a temporary fix for performance issues by reloading the page
                // and putting the user where they last were before the refresh
                const scrollpos = sessionStorage.getItem('scrollpos');
                const savedDatalayer = sessionStorage.getItem('savedDatalayer');

                const doc = (action as ShowDocumentSuccess).document;
                this.setContentLoaded(true);
                this.homeService.updateRecents(doc);
                if (this.currentDoc?.id !== doc.id && scrollpos == null){
                    this.dataLayerService.pageLoadEvent(doc.type, doc.name, doc);
                }
                this.currentDoc = doc;
                this.sessionService.isScrollLocked = true;
                const params = new Proxy(new URLSearchParams(window.location.search), {
                    get: (searchParams, prop) => searchParams.get(prop as string),
                });
                const keywordToJumpTo = (params as any).keyword;
                        this.store.dispatch(new ReloadTableOfContents());

                
                if (scrollpos) {
                    doc.scrollPosition = Number(scrollpos);
                    sessionStorage.removeItem('scrollpos');
                } 
                if (!!savedDatalayer && savedDatalayer != '') {
                    this.dataLayerService.loadUserProfileFromStore();
                    sessionStorage.removeItem('savedDatalayer');
                }

                // Check for section id (fragment) in the URL and if found scroll to that section.
                if (!!this.activatedRoute.snapshot.fragment) {
                    setTimeout(() => {
                        this.sessionService.isScrollLocked = false;
                        if (document.querySelector('#' + this.activatedRoute.snapshot.fragment)) {
                            //document.querySelector('#' + this.activatedRoute.snapshot.fragment).scrollIntoView({block: "nearest", inline: "nearest"});

                            const yOffset = -120;
                            const y = document.querySelector('#' + this.activatedRoute.snapshot.fragment).getBoundingClientRect().top + window.scrollY + yOffset;

                            window.scrollTo({top: y, behavior: 'smooth'});
                        }
                    }, 500);
                } else if (!!keywordToJumpTo && DocumentService.DOC_WITH_KEYWORD_JUMP_ENABLED.includes(doc.id)) {
                    setTimeout(() => {
                        this.sessionService.isScrollLocked = false;
                        const elementToJumpTo = Array.from(document.querySelectorAll('.dita-keyword')).find(el => el.textContent === keywordToJumpTo);
                        if (!!elementToJumpTo) {
                            let elPosition = elementToJumpTo.getBoundingClientRect().top;
                            let loopNum = 0;
                            elementToJumpTo.classList.add('highlighted');

                            // just in case the page is still formatting after the content loads
                            // loop this up to a number of times to ensure we're at the right place
                            while ((elPosition > 500 || elPosition < 0) && loopNum < 30) {
                                this.sessionService.isScrollLocked = false;
                                window.scrollTo({ top: elPosition - 250, behavior: 'auto' });
                                elPosition = elementToJumpTo.getBoundingClientRect().top;
                                loopNum++;
                            }
                        }
                    }, 800);
                } else if (!!doc.scrollPosition) {
                    setTimeout(() => { window.scrollTo({ top: doc.scrollPosition, behavior: 'auto' }); }, 50);
                }
            });
        this.actionListener$.pipe(ofType(DocumentListActionTypes.LoadDocumentFailed))
            .subscribe((action) => {
                const doc = (action as LoadDocumentFailed).document;

                if (doc.name === null || doc.name === '') {
                    doc.name = this.translationService.instant('TabBar.notfound') + ' (' + doc.id + ')';
                }
            });

        this.skeleton = document.createElement('div');
        this.selectedAlpha = new Map<string, string>();
    }

    setScrollPosition(position: number): void {
        const currDoc = this.getDocumentList().find(d => d.isActive === true);

        if (!!currDoc) {
            currDoc.scrollPosition = position;
        }
    }

    resetDocumentVariables(): void {
        this.documentList = [];
        this.contentLoaded$ = new BehaviorSubject<boolean>(false);
        this.skeleton = document.createElement('div');
        this.selectedAlpha = new Map<string, string>();
        this.docWrapperViewContainerRef = null;
        this.componentRefList =  new Array<ComponentRef<DocumentComponent>>();
    }

    getSelectedAlpha(id: string): string {
        return this.selectedAlpha.get(id);
    }

    setSelectedAlpha(id: string, value: string): void {
        this.selectedAlpha.set(id, value);
    }

    checkDocumentLanguages(): void {
        if (this.documentList[0] && this.translationService.currentLang != this.documentList[0].lang) {
            this.store.dispatch(new ChangeDocumentListLanguage(this.translationService.currentLang));
            for (const openedDoc of this.documentList) {
                if (openedDoc.type === AppConstants.MONOGRAPH_TYPE) {
                    this.store.dispatch(new LoadMessageForChangedLanguage(openedDoc));
                } else if (openedDoc.type === AppConstants.MENU_TYPE) {
                    this.store.dispatch(new LoadMenuDocument(openedDoc.id, openedDoc.lang));
                } else if (openedDoc.type === AppConstants.GENERIC_TYPE) {
                    this.store.dispatch(new LoadGenericList());
                }
            }
        }
    }

    getDocumentList(): Array<DocumentItem> {
        return this.documentList;
    }

    logToolUse(type: string, lang: string): Observable<any> {

        const url: string = environment.API_SERVICE_ENDPOINT + '/documents/calculatorLog/' + lang + "/" + type + "/" + AuthService.userProfileState.userProfile.subscriberId + "/" + AuthService.sID;

        return this.httpClient.get(url, { responseType: 'text' });
    }

    getHtmlForDocumentItem(type: string, id: string, lang: string, parentID: string): Observable<any> {

        const sID = AuthService.sID;

        let url: string = environment.API_SERVICE_ENDPOINT + '/documents/' + type + "/" + lang + "/" + id + "/" + AuthService.userProfileState.userProfile.subscriberId + "/" + sID;

        if (type === AppConstants.MA_PI_TYPE) {
            url = environment.API_SERVICE_ENDPOINT + '/documents/' + type + "/" + lang + "/" + parentID + "/" + id + "/" + AuthService.userProfileState.userProfile.subscriberId + "/" + AuthService.sID;
        }  else if (type === AppConstants.RESOURCE_TYPE || type === AppConstants.WHATS_NEW) {
            url = environment.API_SERVICE_ENDPOINT + '/documents/' + type + "/" + lang + "/" + id;
        }

        if (environment.mockup) {
            return this.httpClient.get('assets/mockData/' + type + '/' + id + '.' + lang + '.html', { responseType: 'text' });
        } else {
            return this.httpClient.get(url, { responseType: 'text' });
        }
    }

    getPdfForDocumentItem(urlForPdf: string): Observable<Blob> {
        return this.httpClient.get(urlForPdf, { responseType: 'blob' });
    }

    closeDocument(type: string, id: string, lang: string): void {


        this.store.dispatch(new RemoveDocument(type, id, lang));

        // Clear the component ref upon closing a document
        this.componentRefList = this.componentRefList.filter(c => (c.instance.doc.id !== id && c.instance.doc.type !== type));
    }

    closeAllDocuments(activeDoc: DocumentItem): void {

        this.store.dispatch(new ClearTabs(activeDoc));
        this.showDocument(activeDoc);
    }

    /*    This function is typically triggered by router.navigate but the router.navigate does
    not work if navigating between documents of the same type as the component does not
    reload or recall ngOnInit. The "else" case was added here to enable other functions to
    open a document this way. */
    setDocumentAsCurrent(type: string, id: string, lang: string): void {
        const selectedDocument = this.getDocument(type, id, lang);
        if (selectedDocument !== null) {
            this.store.dispatch(new ShowDocument(selectedDocument));
        } else {
            const currDoc = this.getDocumentList().find(d => d.isActive === true);
            const userId = currDoc ? currDoc.userid : '';
            this.addDocumentToStore(new DocumentItem(type, id, lang, userId, null));
        }
    }

    // this is listened by cps-document.ts, it will call showDocumentPreload after the componentRef's created
    showDocument(selectedDocument: DocumentItem): void {
        this.store.dispatch(new ShowDocument(selectedDocument));
    }

    showCurrentDocument(): void {
        const currDoc = this.getDocumentList().find(d => d.isActive === true);
        this.store.dispatch(new ShowDocument(currDoc));
    }

    toggleLock(): void {
        this.documentLock = !this.documentLock;
    }

    showDocumentPreload(selectedDocument: DocumentItem): void {
        if (selectedDocument == undefined || selectedDocument == null) {
            return;
        }
        const value = JSON.stringify(this.documentList);
        if (selectedDocument.type === AppConstants.TOOLS_TYPE
            || selectedDocument.type === AppConstants.MENU_TYPE
            || selectedDocument.type === AppConstants.DIRECTORY
            || selectedDocument.type === AppConstants.GENERIC_TYPE
            || selectedDocument.type === AppConstants.MEDICAL_DEVICES) {
            this.translationService.get('TabBar.' + selectedDocument.id).pipe(take(1)).subscribe(name => selectedDocument.name = name);
            this.store.dispatch(new ShowDocumentSuccess(selectedDocument, ''));

            if (!!DocumentService.toolMap.get(selectedDocument.id)) {
                if (selectedDocument.isActive) {
                    if (selectedDocument.lang === 'en' && !selectedDocument.logEn) {
                        this.logToolUse(DocumentService.toolMap.get(selectedDocument.id), this.translationService.currentLang).subscribe();
                        selectedDocument.logEn = true;
                        selectedDocument.logFr = false;
                    } else if (selectedDocument.lang === 'fr' && !selectedDocument.logFr) {
                        this.logToolUse(DocumentService.toolMap.get(selectedDocument.id), this.translationService.currentLang).subscribe();
                        selectedDocument.logFr = true;
                        selectedDocument.logEn = false;
                    }
                }
            }

        } else if (!selectedDocument.isInitialized && !this.documentLock) {
            this.documentLock = true;

            this.store.dispatch(new ShowDocumentPreload(selectedDocument));

        } else {
            const component = this.getComponentRef(selectedDocument.type, selectedDocument.id, selectedDocument.lang)
            this.store.dispatch(new ShowDocumentSuccess(selectedDocument, null));
        }
    }

    getDocument(type: string, id: string, lang: string): DocumentItem {
        const selectedDocument = this.documentList.filter(d => d.id === id && d.type === type && d.lang === lang);
        if (selectedDocument !== null && selectedDocument.length > 0) {
            return selectedDocument[0];
        }
        return null;
    }

    isDocumentInitialized(document: DocumentItem): boolean {
        const componentRefForDocument = this.componentRefList.find(c => c.instance.doc === document);
        return componentRefForDocument !== null && componentRefForDocument !== undefined;
    }

    getComponentRefList(): ComponentRef<DocumentComponent>[] {
        return this.componentRefList;
    }
    getComponentRef(type: string, id: string, lang: string): ComponentRef<DocumentComponent> {
        const selectedComponentRef = this.componentRefList
            .filter(c => c.instance.doc.id === id && c.instance.doc.type === type && c.instance.doc.lang === lang);
        if (selectedComponentRef !== null && selectedComponentRef.length > 0) {
            return selectedComponentRef[0];
        }
        return null;
    }

    getDocumentHtml(doc: DocumentItem): HTMLElement {
        const selectedComponentRef = this.componentRefList.find(c => c.instance.doc.id === doc.id && c.instance.doc.type === doc.type && c.instance.doc.lang === doc.lang);
        if (selectedComponentRef == undefined || selectedComponentRef == null) {
            return;
        }
        return selectedComponentRef.instance.htmlElement;
    }

    getCurrentDocHtmlEl(): HTMLElement {
        const selectedComponentRef = this.componentRefList.find(c => c.instance.doc.isActive === true);
        return selectedComponentRef.instance.htmlElement;
    }

    redownloadDocumentHtml(doc: DocumentItem, docType: Type<any>): void {
        const selectedComponentRef = this.componentRefList.find(c => c.instance.doc.id === doc.id && c.instance.doc.type === doc.type && c.instance.doc.lang === doc.lang);
        const thizz = this;
        if (!!selectedComponentRef) {
            this.documentLock = true;
            this.ngZone.runOutsideAngular(() => {
                this.getHtmlForDocumentItem(doc.type, doc.id, doc.lang, doc.parentDocumentID).subscribe(html => {
                    this.documentLock = false;
                    this.ngZone.run(() => {
                        console.log('redownload');
                        const newHtmlParser = new DOMParser();
                        const htmlEl = newHtmlParser.parseFromString(html, 'text/html');
                        thizz.activateComponentRef(doc, docType, htmlEl.documentElement);
                        this.store.dispatch(new ReloadTableOfContents());
                    });
                });
            });
        }
    }

    addDocumentToStore(docItem: DocumentItem): void {
        this.store.dispatch(new AddDocument(docItem, this.translationService.currentLang));
    }

    destroyComponentRefList(): void {
        for (const componentRef of this.componentRefList) {
            componentRef.destroy();
        }
        this.componentRefList = new Array<ComponentRef<DocumentComponent>>();
    }

    createComponentRefForDocIfEmpty(doc: DocumentItem, isActive: boolean, docComponentType: Type<any>, documentElement: HTMLElement): ComponentRef<DocumentComponent> {
        let componentRef = this.getComponentRef(doc.type, doc.id, doc.lang);
        if (componentRef === null) {
            componentRef = this.createComponentRefForDocument(doc, docComponentType);
            componentRef.instance.htmlElement = documentElement;
        }
        return componentRef;
    }

    activateComponentRef(doc: DocumentItem, docType: Type<any>, htmlElement: HTMLElement): void {
        let component = this.getComponentRef(doc.type, doc.id, doc.lang);
        if (!component) {
            component = this.createComponentRefForDocument(doc, docType);
        }
        component.instance.htmlElement = htmlElement;
        this.imageLoader(component.instance.htmlElement);
    }

    // creates an angular component for a document, which is determined by the document type. It becomes a child of a view container
    // NOTE: This method assumes docWrapperViewContainerRef is populated by cps.document.ts's initialize()
    // NOTE: docComponentType is passed in cause otherwise there will be a circular dependency
    createComponentRefForDocument(doc: DocumentItem, docComponentType: Type<any>): ComponentRef<DocumentComponent> {
        if (doc !== null && this.docWrapperViewContainerRef !== null) {

            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(docComponentType);
            const newContainerRef = this.docWrapperViewContainerRef.createComponent<DocumentComponent>(componentFactory);
            newContainerRef.instance.htmlElement = this.skeleton;
            newContainerRef.instance.doc = doc;
            this.componentRefList.push(newContainerRef);
            return newContainerRef;
        }
        return null;
    }

    copyComponentRefForDocument(doc: DocumentItem, docComponentType: Type<any>, newViewContainer: ViewContainerRef): ComponentRef<DocumentComponent> {
        if (doc !== null && this.docWrapperViewContainerRef !== null) {
            const component = this.getComponentRef(doc.type, doc.id, doc.lang);
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(docComponentType);

            // create the new component in the view container
            const newContainerRef = this.docWrapperViewContainerRef.createComponent<DocumentComponent>(componentFactory);
            newContainerRef.instance.doc = component.instance.doc;
            //newContainerRef.instance.htmlElement = JSON.parse(JSON.stringify(component.instance.htmlElement));
            newContainerRef.instance.htmlElement = component.instance.htmlElement;
            newContainerRef.instance.documentWrapperElement = component.instance.documentWrapperElement;

            const result  = this.componentRefList.filter(c => !(c.instance.doc.id === component.instance.doc.id
                && c.instance.doc.type === component.instance.doc.type
                && c.instance.doc.lang === component.instance.doc.lang));
            this.componentRefList = result;
            this.componentRefList.push(newContainerRef);
            //component.destroy();
            return newContainerRef;
        }
        return null;
    }

    createComponentRefCopy(): ComponentRef<DocumentComponent> {
        return null;
    }

    destroyAllComponentRefs(): void {
        for (let containerRef of this.componentRefList) {
            let index = this.docWrapperViewContainerRef.indexOf(containerRef.hostView);
            this.docWrapperViewContainerRef.detach(index);
            containerRef.destroy();
        }
        this.componentRefList = new Array<ComponentRef<DocumentComponent>>();
        this.docWrapperViewContainerRef = null;
    }

    moveComponentRefsToViewContainer(viewContainer: ViewContainerRef): void {
        for (let containerRef of this.componentRefList) {
            let index = this.docWrapperViewContainerRef.indexOf(containerRef.hostView);
            this.docWrapperViewContainerRef.detach(index);
            viewContainer.insert(containerRef.hostView);
        }
    }

    setHtmlElForCurrentDocument(htmlEl: HTMLElement): void {
        const currentComponentRef = this.componentRefList.find(c => c.instance.doc.isActive === true);
        currentComponentRef.instance.htmlElement = htmlEl;
    }

    setHtmlElForDocument(doc: DocumentItem, htmlEl: HTMLElement): void {

        const currentComponentRef = this.componentRefList.find(c => c.instance.doc.id === doc.id && c.instance.doc.type === doc.type && c.instance.doc.lang === doc.lang);
        if (!!currentComponentRef && !!currentComponentRef.instance) {
            currentComponentRef.instance.htmlElement = htmlEl;
        }
    }

    // used by cps-tab-bar to get a documentitem given a path instead of a proper id + type
    getDocumentForPath(id: string, type: string): DocumentItem {
        const matchingDocs = this.getDocumentList().filter(d => d.id === id);
        let doc = null;
        if (matchingDocs !== undefined && matchingDocs.length > 1) {
            doc = matchingDocs.find(d => DocumentItem.getPathFromType(d.type) === type);
        } else if (matchingDocs.length === 1) {
            doc = matchingDocs[0];
        }
        return doc;
    }

    getCurrentlyViewedDocument(): DocumentItem {
        const currDoc = this.getDocumentList().find(d => d.isActive === true);
        return currDoc;
    }

    getCurrentlyActiveComponentRef(): ComponentRef<DocumentComponent> {
        const currentComponentRef = this.componentRefList.find(c => c.instance.doc.isActive === true);
        return currentComponentRef;
    }

    getCurrentlyActiveDocIndex(): number {
        return this.getDocumentList().findIndex(d => d.isActive === true);
    }

    getDocumentListStateObservable(): Observable<DocumentListState> {
        return this.store.select(state => state.documentList);
    }

    fetchHtmlFailure(error: any): void {
        console.log('error getting document html: ' + error);
    }

    // Get List of all available generics
    getGenerics(): Observable<GenericList> {
        if (environment.mockup) {
            const lang = this.translationService.currentLang.toLocaleLowerCase();

            return this.httpClient.get<GenericList>("assets/mockData/GENERIC/generics." + lang + ".json"); // Demo
        } else {

            return this.httpClient.get<GenericList>(environment.API_SERVICE_ENDPOINT + '/search/generic?query=ALL&lang=' + this.translationService.currentLang);
        }
    }

    loadGenericList(): Observable<GenericListState> {
        return this.store.select(state => state.genericListKey);
    }

    // Get monographs with selected generic
    getGenericMono(generic: string): Observable<GenericItems> {

        if (environment.mockup) {
            return this.httpClient.get<GenericItems>("assets/mockData/GENERIC/" + generic + ".json"); // Demo
        } else {
            return this.httpClient.get<GenericItems>(environment.API_SERVICE_ENDPOINT + '/search/generic?query=' + generic + '&publications=MONOGRAPH&lang=' + this.translationService.currentLang);
        }
    }

    getDocumentDirectoryData(docType: string, lang: string): Observable<string> {
        let url: string = environment.API_SERVICE_ENDPOINT + '/documents/MENU' + "/" + lang + "/" + docType;
        if (environment.mockup) {
            return this.httpClient.get("assets/mockData/MENU/" + docType + '.' + lang + ".json", { responseType: 'text' }); //Demo
        } else {
            return this.httpClient.get(url, { responseType: 'text' });
        }
    }

    isDocumentInOpenedDocList(type: string, id: string, lang: string): boolean {
        const doc = this.documentList.find(d => d.id == id && d.type == type && d.lang == lang);
        return !!doc;
    }

    navigateToDoc(path: string): void {
        const currDoc = this.getCurrentlyViewedDocument();
        currDoc.scrollPosition = window.scrollY;
        this.ngZone.run(() => this.router.navigate([path]));
    }

    navigateToLocator(type: string, docId: string, frag?: string, queryString?: string): void {
        // if the docId includes slashes, we need to use navigateByUrl and use an absolute path
        let docUrl = '/document/' + DocumentItem.getPathFromType(type) + '/' + docId;

        if (docId == 'pccDirectory') {
            docUrl = DocumentItem.getPathFromType(AppConstants.RESOURCE_TYPE) + '/' + 'poisoncontrol';
            this.ngZone.run(() => this.router.navigateByUrl(docUrl));
        }

        else if (docId == 'cpsEdPolicy') {
            const url = this.routerPipe.transform(docId);
            window.open(url);
        } 

        else if (docId == 'manufacturers_directory') {
            docUrl = DocumentItem.getPathFromType(AppConstants.DIRECTORY) + '/' + 'manufacturers';
            this.ngZone.run(() => this.router.navigateByUrl(docUrl));
        }

        else if (type == 'IFP') {
            let path = docUrl.slice(0, docUrl.lastIndexOf('/'));
            const currDoc = this.getCurrentlyViewedDocument();
            currDoc.scrollPosition = window.scrollY;
            this.ngZone.run(() => this.router.navigate([path]));
        }

        else if (docId.indexOf('/') > -1) {
            this.ngZone.run(() => this.router.navigateByUrl(docUrl));
        }
        else if (docId == 'RENALFUNCTIONCALCULATOR'){
            docUrl = DocumentItem.getPathFromType(AppConstants.TOOLS_TYPE) + '/' + 'renalfunction';
            this.ngZone.run(() => this.router.navigateByUrl(docUrl));
        }

        else {

            const currDoc = this.getCurrentlyViewedDocument();
            currDoc.scrollPosition = window.scrollY;

            // Do this if we are trying to scroll to a specific section of the document
            if (!!frag || !!queryString) {
                if (!!queryString) {
                    docUrl += '?' + queryString;
                }
                if (!!frag) {
                    frag = frag.replace('#', '');
                    this.router.navigate([docUrl], { fragment: frag });
                } else {
                    this.ngZone.run(() => this.router.navigateByUrl(docUrl));
                }

            } else {
                this.ngZone.run(() => this.router.navigate(['document', DocumentItem.getPathFromType(type), docId]));
            }
            if (currDoc.type === type) {
                this.setDocumentAsCurrent(type, docId, currDoc.lang);
            }
        }
    }

    imageLoader(html: HTMLElement): void {
        const images = html.getElementsByTagName('img');
        for (const image in images) {

            // check if there is a src or data-src, if there is a src check if it includes loadimage
            if (!isNaN(+image) && images[image] != null && images[image].getAttribute('data-src') !== null) {

                const imgFullLink = environment.API_SERVICE_ENDPOINT + '/' + images[image].getAttribute('data-src');
                images[image].setAttribute('src', imgFullLink);

            } else if (!isNaN(+image) && images[image] !== null && images[image].getAttribute('src') !== null && images[image].getAttribute('src').includes('msg_image')) {

                if (!images[image].getAttribute('src').includes(environment.API_SERVICE_ENDPOINT)) {
                    const imgFullLink = environment.API_SERVICE_ENDPOINT + images[image].getAttribute('src');
                    images[image].setAttribute('src', imgFullLink);
                    images[image].setAttribute('style', 'width:100%; height:100%');
                }
            }
        }
    }

    imageReader(data: Blob, element: HTMLImageElement): void {

        const reader = new FileReader();

        reader.addEventListener('error', () => {
            console.error('Error occurred reading file: $');

            this.httpClient.get(this.brokenImagePath, { responseType: 'blob' }).subscribe(blobData => {
                this.imageReader(blobData, element);
            });
        });

        reader.readAsDataURL(data);
        reader.onloadend = () => {
            const base64data = reader.result;
            element.setAttribute('src', base64data.toString());
        };
    }

    getMenuDocument(): Observable<Map<string, MenuDocumentData>> {
        return this.store.select(selectDocumentData);
    }

    getSpecificMenuDocument(id: string): Observable<MenuDocumentData> {
        return this.store.select(selectSpecificDocumentData(id));
    }

    adjustTMsymbol(element):void{
        const tmSymbols = element.querySelectorAll('sup.tm')
        tmSymbols.forEach(tm => {
            if (((tm as HTMLElement).innerText == '™'))
                tm.classList.add("alreadySmallTM");
        })
        tmSymbols.forEach(tm => {
            if (((tm as HTMLElement).innerText == '®'))
                tm.classList.add("alreadySmallR");
        })
        return ;
        // This is to make the french product symbol in din-info-table appear at propersize
    }

    stickyTableSections(docId = null, offset = 210): void {
        const intersectionObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                const table = entry.target as HTMLTableElement;
                this.adjustTMsymbol(table);
                const thead = table.querySelector('thead') as HTMLElement;
                const subHeaders = table.querySelectorAll('.subHeader');
                const scrollableContainer = table.parentNode as HTMLElement
                const overflow = scrollableContainer.clientWidth + 8 < table.scrollWidth;
                table.querySelectorAll('p,span').forEach(function (el) {
                    const element = el as HTMLElement;
                    let htmlContent = element.innerHTML;
                    htmlContent = htmlContent.replace(/(\d) (?![^<]*>)/g, '$1\u00A0');
                    htmlContent = htmlContent.replace(/ (?=\d)(?![^<]*>)/g, '\u00A0');
                    element.innerHTML = htmlContent;
                });
                
                if (scrollableContainer && table.rows.length>2) {
                       // There are table elements in cps that don't appear as tables bc they are too small, we don't want scroll bar for them.
                    if(this.translationService.currentLang === 'fr') {
                        scrollableContainer.classList.add("langfr");
                    } else if (this.translationService.currentLang === 'en') {
                        scrollableContainer.classList.add("langen");
                    }
                    if (overflow) {                                 
                        scrollableContainer.classList.add("boxedScrollableTable");
                            if (thead)
                                thead.classList.remove("stickyHeaderNoOverflowX");
                    }
                    else {
                        scrollableContainer.classList.remove("boxedScrollableTable");
                        if (thead) {
                            thead.classList.add("stickyHeaderNoOverflowX");
                        }
                    }
                }
                    subHeaders.forEach(row => {
                            if (entry.intersectionRatio > 0) {
                                (row as HTMLElement).style.position = 'sticky';
                                if (overflow){
                                    (row as HTMLElement).style.top = (thead.getBoundingClientRect().height) + 'px';
                                }
                                else {
                                    if (window.innerWidth <= 768) { 
                                        (row as HTMLElement).style.top = (thead.getBoundingClientRect().height) + 248 + 'px';
                                    }
                                    else{
                                        (row as HTMLElement).style.top = (thead.getBoundingClientRect().height) + 'px';
                                    }
                                }
                            } else {
                                (row as HTMLElement).style.position = 'relative';
                            }
                    });
            });
        });

        const observeTables = () => {
            document.querySelectorAll('table').forEach((table) => {
                intersectionObserver.observe(table);
            });
        };

        observeTables(); // Observe tables initially, otherwise first table in each page not effected by this

        const mutationObserver = new MutationObserver(() => {
            observeTables(); // Re-observe tables if mutations occur
        });

        if (docId !== null) {
            mutationObserver.observe(document.querySelector('#' + docId) as Node, { childList: true, subtree: true });
        } else {
            mutationObserver.observe(document.body, { childList: true, subtree: true });
        }
    }

    getFile(fileName: string, token: string): Observable<any> {
        const headers = new HttpHeaders({
            'Authorization': 'Bearer' + token
        });
        const p = new HttpParams();
        const url = environment.API_SERVICE_ENDPOINT + fileName;
        return this.httpClient.get(url, { params: p, responseType: 'blob', headers: headers });
    }

    setContentLoaded(state: boolean): void {
        this.contentLoaded$.next(state);
    }

    getContentLoaded(): Observable<boolean> {
        return this.contentLoaded$.asObservable();
    }

    setDisplayFullHTML(state: boolean): void {
        this.displayFullHTML$.next(state);
    }

    getDisplayFullHTML(): Observable<boolean> {
        return this.displayFullHTML$.asObservable();
    }

    scrollToActiveDocumentSection(sectionName: String): void {
        this.store.dispatch(new ScrollToSection(sectionName));
    }

    scrollActionListenerObservable(): Observable<Action> {
        return this.actionListener$.pipe(ofType(DocumentListActionTypes.ScrollToSection));
    }

    removeHtmlFromCurrentDocument(): void {
        const currDocComponentRef = this.componentRefList.find(c => c.instance.doc.isActive === true);
        const index = this.componentRefList.indexOf(currDocComponentRef);
        //currDocComponentRef.instance.htmlElement.innerHTML = 'null and void';
        //this.componentRefList = this.componentRefList.splice(index, 1);
        //currDocComponentRef.destroy();
        this.componentRefList[index] = currDocComponentRef;
    }
}



